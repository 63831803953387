import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image"
import Layout from 'components/Layout';

export const query = graphql`
query {
    img_attest_screen: file(relativePath: {eq: "jpg/attest-screen.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    img_attest_usertesting: file(relativePath: {eq: "png/attest-usertests.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    img_attest_ideation: file(relativePath: {eq: "png/attest-ideation.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    img_attest_floater_1: file(relativePath: {eq: "png/attest-floater-1.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    img_attest_floater_2: file(relativePath: {eq: "png/attest-floater-2.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    img_attest_floater_3: file(relativePath: {eq: "png/attest-floater-3.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
  }
`




const AttestPage = ({ data }) => {
    useEffect(() => {
        // Floater animation
        const bg = document.querySelector('body');

        const windowWidth = window.innerWidth / 5;
        const windowHeight = window.innerHeight / 5 ;


        bg.addEventListener('mousemove', (e) => {
        const mouseX = e.clientX / windowWidth;
        const mouseY = e.clientY / windowHeight;
        const floaters = document.querySelectorAll('.c-floater');
        for (var i = 0; i < floaters.length; i++) {
                floaters[i].style.transform = `translate3d(-${mouseX*[i+3]}%, -${mouseY*[i+3]}%, 0)`;
        };
        });
    });
    return (
        <Layout pageName="attest">
            <Helmet>
                <title>Attest - Dries Standaert - Digital product designer</title>
                <meta name="description" content="A selection of growth experiments for Attest"/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap" rel="stylesheet"/>
                <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Roboto:wght@400;500;700&display=swap" rel="stylesheet"/>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <main id="main-content">
                <section className="c-row c-row--alpha c-row-hero">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp2-10 f-col-bp3-8 c-hero" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-anchor="hero">
                            <h1>Facilitating growth experiments for Attest</h1>
                            <ul className="o-list o-list--horizontal c-tags--hero">
                                <li className="o-list__item c-tag">Research</li>
                                <li className="o-list__item c-tag">IA</li>
                                <li className="o-list__item c-tag">Prototyping</li>
                                <li className="o-list__item c-tag">Visual design</li>
                                <li className="o-list__item c-tag">Testing</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="c-row c-row-normal u-bgcolor-neutral-light">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-8 f-col-bp3-shift-2">
                            {/*
                            <div className="c-browser c-browser--dark" data-aos="fade-up">
                                <div className="c-browser__content">
                                    <div>
                                        <Img fluid={data.img_attest_template.childImageSharp.fluid} alt="Attest template" />
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="" data-aos="fade-up">
                                <div className="c-browser c-browser--dark">
                                    <div>
                                        <Img fluid={data.img_attest_screen.childImageSharp.fluid} alt="Attest template" />
                                    </div>
                                    <div className="c-floater c-floater--1">
                                        <Img fluid={data.img_attest_floater_1.childImageSharp.fluid} alt="Attest floater" />
                                    </div>
                                    <div className="c-floater c-floater--2">
                                        <Img fluid={data.img_attest_floater_2.childImageSharp.fluid} alt="Attest floater" />
                                    </div>
                                    <div className="c-floater c-floater--3">
                                        <Img fluid={data.img_attest_floater_3.childImageSharp.fluid} alt="Attest floater" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="c-row c-row--beta">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-8 f-col-bp3-shift-2" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-anchor="hero">
                            <div className="c-tile__content c-tile__content--text u-pt-alpha">
                                <h2>Introducing growth experiments</h2>
                                <p>I was part of introducing growth experiments at Attest. A growth experiment is a systematic method for testing any growth-related improvements we made to the website.</p>
                                <p>They could be as complex as creating a new section (eg Academy for educational content) or as small as A/B testing a call-to-action. In essence they follow the same format.</p>
                                <p className="c-callout">For example, our homepage bounce rate was north of 55%. A high bounce rate might indicate that potential prospects do not find what there are after. So we set out to understand why users drop out, and therefore, if we should/could make improvements.</p>
                            </div>
                        </div>
                    </div>
                </section>
            
                <section className="c-row c-row--alpha">
                    <div className="f-grid u-reverse">
                        <div className="f-col-bp1-4 f-col-bp3-6 u-ver-center">
                            <div className="c-tile__content">
                                <h2>Research & User testing</h2>
                                <p>Analytics helped us to spot anomalies in the user flows and heat maps could inform us to a certain extent where users are able to digest information and/or where they were likely to bounce.</p>
                                <p>With this mind, I conducted user tests to understand how users interact with certain website pages or elements. The main objective of our user tests was to confirm or distill a hypothesis that we were confident to work with.</p>
                            </div>
                        </div>
                        <div className="f-col-bp1-4 f-col-bp3-6">
                            <div className="c-tile__content c-tile__content--img">
                                <figure>
                                    <Img fluid={data.img_attest_usertesting.childImageSharp.fluid} alt="Attest template" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                
                
                <section className="c-row c-row--beta">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-8 f-col-bp3-shift-2">
                            <div className="c-tile__content c-tile__content--text">
                                <h2>Results & Hypothesis</h2>
                                <p>Our test results often confirmed that potential prospects did not always find what they are looking for.</p>
                                <div className="c-callout u-mb-epsilon">
                                    <p>On the homepage we wanted to found out if users understand what Attest does at a glance, what their expectations were, what triggers them to find out more and what holds them back if they don’t.</p>
                                    <p>Here are some general findings*:</p>
                                    <ul>                                        
                                        <li className=""><strong>Users are sceptical about stand-alone company logos.</strong> They want to see social proof by companies they can resonate with. Numbers spark the most interest.</li>
                                        <li className=""><strong>Users want clear USP’s.</strong> They want to instantly understand what makes you different than your competitors.</li>
                                        <li className=""><strong>Users want to see the product in action.</strong> Showing the user interface seems to give an instant impression (and ideally confirmation) on the quality and ease-of-use of the product.</li>
                                    </ul>
                                </div>
                                <p>*Note that I only mention general findings here for the sake of brevity. Some of our testing honed into content (eg. understanding the wording of our value proposition and/or USPs) and the overall layout (eg. clarity, use of colour, illustrations, etc).</p>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="c-row c-row--alpha">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-8 f-col-bp3-shift-2">
                            <div className="c-tile__content c-tile__content--text u-pb-gamma">
                                <h2>Ideation & Prototyping</h2>
                                <p>Depending on the nature of the experiment this stage could involve facilitating a workshop to generate ideas, mapping out user flows, questioning our information architecture, etc. before prototyping.</p>
                                <p>My prototypes can come in different forms. Low-fi static designs help me to work out quick ideas or to find the correct hierarchy of information. High-fi (interactive) designs focus more on user interaction and user flow.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="c-row c-row--alpha">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-8 f-col-bp3-shift-2">
                            <div>
                                <Img fluid={data.img_attest_ideation.childImageSharp.fluid} alt="Attest ideation" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="c-row c-row--alpha">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-8 f-col-bp3-shift-2">
                            <div className="c-tile__content c-tile__content--text">
                                <h3>Coding</h3>
                                <p>I often prototype certain elements or pages in code. I find that it adds a layer of interaction or animation that would otherwise be missed with static prototypes. it allows me to get accurate user feedback (through design crits or user tests). It also helps to communicate the intention with developers.</p>
                                <p>Visit my <a href="http://codepen.io/driesstandaert/" target="_blank" rel="noreferrer" className="c-link c-link--external">Codepen</a> for some examples.</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/*
                <section className="c-row c-row--normal">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-12">
                            <iframe height="800" width="100%" scrolling="no" title="Attest - hero exploration (Grid)" src="https://codepen.io/driesstandaert/embed/zYJZvGO?default-tab=result" frameBorder="no" loading="lazy" allowtransparency="true">
                                See the Pen <a href="https://codepen.io/driesstandaert/full/zYJZvGO">
                                Attest - hero exploration (Grid)</a> by driesstandaert (<a href="https://codepen.io/driesstandaert">@driesstandaert</a>)
                                on <a href="https://codepen.io">CodePen</a>.
                            </iframe>
                           
                        </div>
                    </div>
                </section>
                */}
                <section className="c-row c-row--beta">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-8 f-col-bp3-shift-2">
                            <div className="c-tile__content c-tile__content--text">
                                <h2>Testing & iterations</h2>
                                <p>Finally we monitored our changes over time and see if we were able move the needle. If not, we tried again. Sometimes OKRs change which gave us an incentive to tweak initial experiments or start new ones.</p>
                                <p className="c-callout">For the homepage I ran another user test (with a different pool of participants) which confirmed that the improvements we made had an immediate effect. Within a few months we were able to reduce the bounce rate to 40%.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="c-row u-nomargin u-bgcolor-neutral-x-light">
                    <div className="f-grid u-no-max-width">
                        <Link to="/tw/" className="f-col-bp3-6 f-col-bp3-shift-6 c-tile c-tile--link">
                            <div className="c-tile__content c-tile__content--link">
                                <p className="c-tile__link-text c-tile__link-text--next"><span>Thames Water</span></p>
                            </div>
                        </Link>
                    </div>
                </section>
            </main>
        </Layout>
    )
};

export default AttestPage;

